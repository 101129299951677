import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import './BibleReader.css';
import ChatGptPanel from './ChatGptPanel';

function BibleReader({ bibleData }) {
  const { book, chapter } = useParams();
  const [content, setContent] = useState(null);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [footnotes, setFootnotes] = useState({});
  const [activeFootnote, setActiveFootnote] = useState(null);
  const [selectedVerses, setSelectedVerses] = useState([]);
  const [gptPanelOpen, setGptPanelOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const normalizeBookName = (name) => {
    if (!name) return '';
    return name.replace(/^(\d+|I{1,3}V?|V?I{1,3})\s+/, (match) => {
      const roman = ['I', 'II', 'III', 'IV', 'V'];
      // Remove this line if it's not used:
      // const arabic = ['1', '2', '3', '4', '5'];
      if (roman.includes(match.trim())) {
        return match; // It's already a Roman numeral, keep it as is
      }
      const number = parseInt(match);
      return roman[number - 1] + ' ';
    });
  };

  useEffect(() => {
    if (bibleData && bibleData.books) {
      const bookData = bibleData.books.find(b => normalizeBookName(b.chapter) === normalizeBookName(book));
      if (bookData) {
        if (chapter === 'all') {
          setContent({ book: bookData.chapter, chapters: bookData.verses });
        } else {
          const chapterIndex = parseInt(chapter) - 1;
          if (chapterIndex >= 0 && chapterIndex < bookData.verses.length) {
            setContent({
              book: bookData.chapter,
              chapter: parseInt(chapter),
              verses: bookData.verses[chapterIndex]
            });
          } else {
            setContent(null); // Chapter not found
          }
        }
      } else {
        setContent(null); // Book not found
      }
    }
    // Reset selected verses when changing chapters
    setSelectedVerses([]);
  }, [book, chapter, bibleData]);

  const highlightText = useCallback((text, highlight) => {
    if (!highlight.trim()) {
      return <span>{text}</span>;
    }
    const regex = new RegExp(`(${highlight})`, 'gi');
    const parts = text.split(regex);
    return (
      <span>
        {parts.filter(String).map((part, i) => 
          regex.test(part) ? <mark key={i}>{part}</mark> : <span key={i}>{part}</span>
        )}
      </span>
    );
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchTerm(searchTerm);
  };

  const handleVerseSelection = (verseIndex) => {
    setSelectedVerses(prev => 
      prev.includes(verseIndex)
        ? prev.filter(v => v !== verseIndex)
        : [...prev, verseIndex].sort((a, b) => a - b)
    );
  };

  const renderVerse = (verse, verseIndex, chapterIndex = null) => {
    const words = verse.map(word => word[0]).join(' ');
    const footnoteKey = `${book}-${chapterIndex !== null ? chapterIndex + 1 : chapter}-${verseIndex + 1}`;
    const isSelected = selectedVerses.includes(verseIndex);
    return (
      <p key={verseIndex} className={isSelected ? 'selected-verse' : ''}>
        <input
          type="checkbox"
          checked={isSelected}
          onChange={() => handleVerseSelection(verseIndex)}
        />
        <strong>{verseIndex + 1}</strong> {highlightText(words, searchTerm)}
        <sup 
          className="reference" 
          onClick={() => handleFootnoteClick(footnoteKey)}
        >
          [{verseIndex + 1}]
        </sup>
      </p>
    );
  };

  const handleFootnoteClick = (key) => {
    if (!footnotes[key]) {
      // In a real application, you'd fetch this data from an API or database
      setFootnotes(prev => ({
        ...prev,
        [key]: `Explanation for ${key}`
      }));
    }
    setActiveFootnote(key);
  };

  const NavigationControls = () => {
    if (!bibleData || !bibleData.books) {
      return null; // or return a loading indicator
    }

    const currentBookIndex = bibleData.books.findIndex(b => b.chapter === book);
    if (currentBookIndex === -1) {
      return null; // or handle this case appropriately
    }

    const currentBook = bibleData.books[currentBookIndex];
    const chapterNumber = parseInt(chapter);
    const totalChapters = currentBook.verses.length;

    const goToPreviousChapter = () => {
      if (chapterNumber > 1) {
        navigate(`/read/${book}/${chapterNumber - 1}`);
      } else if (currentBookIndex > 0) {
        const previousBook = bibleData.books[currentBookIndex - 1];
        navigate(`/read/${previousBook.chapter}/${previousBook.verses.length}`);
      }
    };

    const goToNextChapter = () => {
      if (chapterNumber < totalChapters) {
        navigate(`/read/${book}/${chapterNumber + 1}`);
      } else if (currentBookIndex < bibleData.books.length - 1) {
        const nextBook = bibleData.books[currentBookIndex + 1];
        navigate(`/read/${nextBook.chapter}/1`);
      }
    };

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
        <div>
          <button onClick={goToPreviousChapter} disabled={chapterNumber === 1 && currentBookIndex === 0}>
            Previous Chapter
          </button>
          <button 
            onClick={() => setGptPanelOpen(true)}
            style={{ marginLeft: '10px' }}
          >
            Open ChatGPT Panel
          </button>
        </div>
        <button onClick={goToNextChapter} disabled={chapterNumber === totalChapters && currentBookIndex === bibleData.books.length - 1}>
          Next Chapter
        </button>
      </div>
    );
  };

  const handleTextSelection = () => {
    const selection = window.getSelection();
    if (selection && selection.toString().trim()) {
      return selection.toString();
    }
    return '';
  };

  const getSelectedText = () => {
    // First check for direct text selection
    const selection = window.getSelection();
    if (selection && selection.toString().trim()) {
      return selection.toString();
    }
    
    // If no text is directly selected, use checkbox selections
    if (chapter === 'all') {
      return selectedVerses.map(verse => {
        const [chapterIndex, verseIndex] = verse.split('-');
        return content.chapters[chapterIndex][verseIndex]
          .map(word => word[0])
          .join(' ');
      }).join(' ');
    } else {
      return selectedVerses
        .map(index => content.verses[index]
          .map(word => word[0])
          .join(' '))
        .join(' ');
    }
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      // If selecting all, get all verse indices
      const allVerses = chapter === 'all' 
        ? content.chapters.flatMap((_, chapterIndex) => 
            content.chapters[chapterIndex].map((_, verseIndex) => `${chapterIndex}-${verseIndex}`)
          )
        : content.verses.map((_, index) => index);
      setSelectedVerses(allVerses);
    } else {
      // If deselecting all, clear the selection
      setSelectedVerses([]);
    }
  };

  const handleInverseSelection = () => {
    if (chapter === 'all') {
      const allPossibleVerses = content.chapters.flatMap((_, chapterIndex) => 
        content.chapters[chapterIndex].map((_, verseIndex) => `${chapterIndex}-${verseIndex}`)
      );
      const inverseSelection = allPossibleVerses.filter(
        verseIndex => !selectedVerses.includes(verseIndex)
      );
      setSelectedVerses(inverseSelection);
    } else {
      const allPossibleVerses = content.verses.map((_, index) => index);
      const inverseSelection = allPossibleVerses.filter(
        index => !selectedVerses.includes(index)
      );
      setSelectedVerses(inverseSelection);
    }
    setSelectAll(false);
  };

  if (!content) {
    return <div>Content not found. Please check the book and chapter.</div>;
  }

  return (
    <div className={`bible-reader-container ${gptPanelOpen ? 'panel-open' : ''}`}>
      <div className="bible-header">
        <div className="header-left">
          <Link to={`/book/${content.book}`}>Back to {content.book}</Link>
          <h2>{book} {chapter !== 'all' && `- Chapter ${chapter}`}</h2>
        </div>
        <button 
          onClick={() => setGptPanelOpen(!gptPanelOpen)}
          className="gpt-toggle-button"
        >
          {gptPanelOpen ? 'Close ChatGPT Panel' : 'Open ChatGPT Panel'}
        </button>
      </div>

      <div className="bible-content" onMouseUp={handleTextSelection}>
        <form onSubmit={handleSearch}>
          <input 
            type="text" 
            value={searchTerm} 
            onChange={(e) => setSearchTerm(e.target.value)} 
            placeholder="Search this chapter..."
          />
          <button type="submit">Search</button>
        </form>

        <div className="select-all-container">
          <input
            type="checkbox"
            checked={selectAll}
            onChange={handleSelectAll}
          />
          <label>Select All Verses</label>
          <button 
            onClick={handleInverseSelection}
            className="inverse-button"
          >
            Inverse Selection
          </button>
        </div>

        {chapter === 'all' ? (
          content.chapters.map((chapterVerses, chapterIndex) => (
            <div key={chapterIndex}>
              <h3>Chapter {chapterIndex + 1}</h3>
              {chapterVerses.map((verse, verseIndex) => renderVerse(verse, verseIndex, chapterIndex))}
            </div>
          ))
        ) : (
          content.verses && content.verses.map((verse, index) => renderVerse(verse, index))
        )}

        {activeFootnote && (
          <div className="footnote">
            <h4>Footnote for {activeFootnote}</h4>
            <p>{footnotes[activeFootnote]}</p>
            <button onClick={() => setActiveFootnote(null)}>Close</button>
          </div>
        )}

        {gptPanelOpen && (
          <ChatGptPanel 
            selectedText={getSelectedText()}
            currentBook={book}
            currentChapter={chapter}
            onClose={() => setGptPanelOpen(false)}
          />
        )}

        {chapter !== 'all' && <NavigationControls />}
      </div>
    </div>
  );
}

export default BibleReader;
