import React, { useState } from 'react';
import axios from 'axios';
import './ChatGptPanel.css';

function ChatGptPanel({ selectedText, onClose, currentBook, currentChapter }) {
  const [gptResponse, setGptResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [customQuestion, setCustomQuestion] = useState('');
  const [responseLength, setResponseLength] = useState('single'); // Changed from 'medium'
  const [activeTooltip, setActiveTooltip] = useState(null);

  const tooltips = {
    rephrase: "Rewrites the selected verses in modern, easy-to-understand language while preserving the original meaning",
    explain: "Provides a detailed explanation of the selected verses' meaning and significance in Christian context",
    compare: "Shows how different Bible translations interpret these verses and highlights key differences",
    analyze: "Performs a comprehensive analysis of the entire chapter's themes, teachings, and theological concepts",
    summary: "Creates a concise summary of the chapter's main points and key messages",
    question: "Allows you to ask specific questions about the chapter or selected verses"
  };

  const getLengthSettings = (length) => {
    switch (length) {
      case 'single': // 1 sentence
        return { max_tokens: 50, instructions: 'Respond in a single sentence.' };
      case 'short': // ~3-4 sentences
        return { max_tokens: 150, instructions: 'Respond in 3-4 sentences.' };
      case 'medium': // ~1 paragraph
        return { max_tokens: 300, instructions: 'Respond in one paragraph.' };
      case 'long': // ~1/2 page
        return { max_tokens: 750, instructions: 'Respond in about half a page.' };
      case 'full': // full page
        return { max_tokens: 1500, instructions: 'Provide a detailed, full-page response.' };
      default:
        return { max_tokens: 50, instructions: 'Respond in a single sentence.' };
    }
  };

  const handleGptRequest = async (action) => {
    setIsLoading(true);
    setError(null);

    const lengthSettings = getLengthSettings(responseLength);
    let prompt = '';
    switch (action) {
      case 'rephrase':
        prompt = `As a Christian biblical scholar, rephrase this scripture in modern language while maintaining its original theological meaning: "${selectedText}". ${lengthSettings.instructions}`;
        break;
      case 'explain':
        prompt = `From a Christian theological perspective, explain the meaning and significance of this scripture: "${selectedText}"`;
        break;
      case 'compare':
        prompt = `Compare this scripture across major Christian Bible translations, highlighting key differences in interpretation: "${selectedText}"`;
        break;
      default:
        prompt = selectedText;
    }

    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: "gpt-4",
          messages: [{
            role: "user",
            content: prompt
          }],
          max_tokens: lengthSettings.max_tokens,
          temperature: 0.7,
        },
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setGptResponse(response.data.choices[0].message.content);
    } catch (err) {
      console.error('Full error:', err);
      setError('An error occurred while fetching the response. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleChapterAnalysis = async () => {
    setIsLoading(true);
    setError(null);
    
    const lengthSettings = getLengthSettings(responseLength);
    const prompt = `
      As a Christian biblical scholar, analyze ${currentBook} chapter ${currentChapter} from a Christian theological perspective:
      1. Provide a faithful chapter summary adhering to Christian doctrine
      2. Explain how this chapter relates to the overall message of ${currentBook} and Christian teachings
      3. List key themes and their connections to other Bible passages, focusing on Christian interpretation
      4. Identify major theological concepts present in Christian doctrine
      Please format the response with clear sections and include specific verse references.
      Base your analysis on traditional Christian understanding and interpretation.
      ${lengthSettings.instructions}
    `;

    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: "gpt-4",
          messages: [{
            role: "user",
            content: prompt
          }],
          max_tokens: lengthSettings.max_tokens,
          temperature: 0.7,
        },
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setGptResponse(response.data.choices[0].message.content);
    } catch (err) {
      setError('Failed to generate chapter analysis');
    } finally {
      setIsLoading(false);
    }
  };

  const handleChapterSummary = async () => {
    setIsLoading(true);
    setError(null);

    const lengthSettings = getLengthSettings(responseLength);
    const prompt = `
      As a Christian biblical scholar, provide a comprehensive analysis of ${currentBook} chapter ${currentChapter}:
      1. Chapter Summary:
         - Main events and teachings from a Christian perspective
         - Key verses central to Christian doctrine
      
      2. Book Context:
         - How this chapter fits into ${currentBook}'s narrative in Christian scripture
         - Major themes developed in Christian theology
      
      3. Biblical Connections:
         - Cross-references to other Bible passages supporting Christian doctrine
         - Related theological concepts in Christian teaching
         - Prophecies fulfilled or referenced in Christian understanding
      
      4. Application:
         - Key lessons for Christian believers
         - Practical implications for Christian living
      
      Format the response with clear headings and include specific verse references.
      Base all interpretations on traditional Christian understanding and biblical truth.
      Avoid secular or non-Christian interpretations.
      ${lengthSettings.instructions}
    `;

    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: "gpt-4",
          messages: [{
            role: "user",
            content: prompt
          }],
          max_tokens: lengthSettings.max_tokens,
          temperature: 0.7,
        },
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setGptResponse(response.data.choices[0].message.content);
    } catch (err) {
      console.error('Error:', err);
      setError('Failed to generate chapter summary');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCustomQuestion = async () => {
    setIsLoading(true);
    setError(null);

    const lengthSettings = getLengthSettings(responseLength);
    const prompt = `
      As a Christian biblical scholar, answer this question about ${currentBook} chapter ${currentChapter}:
      "${customQuestion}"
      
      Please provide an answer that:
      - References specific verses when relevant
      - Maintains Christian theological perspective
      - Connects to broader biblical context when appropriate
      - Provides practical application for believers
      ${lengthSettings.instructions}
    `;

    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: "gpt-4",
          messages: [{
            role: "user",
            content: prompt
          }],
          max_tokens: lengthSettings.max_tokens,
          temperature: 0.7,
        },
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setGptResponse(response.data.choices[0].message.content);
      setCustomQuestion(''); // Clear the input after sending
    } catch (err) {
      console.error('Error:', err);
      setError('Failed to get an answer. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const HelpButton = ({ type }) => (
    <button 
      className="help-button"
      onClick={(e) => {
        e.stopPropagation();
        setActiveTooltip(activeTooltip === type ? null : type);
      }}
    >
      ?
    </button>
  );

  return (
    <div className="chat-gpt-panel">
      <h3>ChatGPT Options</h3>
      <div className="response-length-selector">
        <label>Response Length:</label>
        <select 
          value={responseLength}
          onChange={(e) => setResponseLength(e.target.value)}
          disabled={isLoading}
        >
          <option value="single">Single Sentence</option>
          <option value="short">Brief (3-4 sentences)</option>
          <option value="medium">One Paragraph</option>
          <option value="long">Half Page</option>
          <option value="full">Full Page</option>
        </select>
      </div>
      
      <div className="button-group">
        <button onClick={() => handleGptRequest('rephrase')} disabled={isLoading}>
          Rephrase in modern language
        </button>
        <HelpButton type="rephrase" />
        {activeTooltip === 'rephrase' && <div className="tooltip">{tooltips.rephrase}</div>}
      </div>

      <div className="button-group">
        <button onClick={() => handleGptRequest('explain')} disabled={isLoading}>
          Explain meaning
        </button>
        <HelpButton type="explain" />
        {activeTooltip === 'explain' && <div className="tooltip">{tooltips.explain}</div>}
      </div>

      <div className="button-group">
        <button onClick={() => handleGptRequest('compare')} disabled={isLoading}>
          Compare to other versions
        </button>
        <HelpButton type="compare" />
        {activeTooltip === 'compare' && <div className="tooltip">{tooltips.compare}</div>}
      </div>

      <div className="button-group">
        <button onClick={handleChapterAnalysis} disabled={isLoading}>
          Analyze Chapter
        </button>
        <HelpButton type="analyze" />
        {activeTooltip === 'analyze' && <div className="tooltip">{tooltips.analyze}</div>}
      </div>

      <div className="button-group">
        <button 
          onClick={handleChapterSummary} 
          disabled={isLoading || !currentChapter || currentChapter === 'all'}
        >
          Generate Chapter Summary
        </button>
        <HelpButton type="summary" />
        {activeTooltip === 'summary' && <div className="tooltip">{tooltips.summary}</div>}
      </div>

      <div className="question-section">
        <div className="button-group">
          <input
            type="text"
            value={customQuestion}
            onChange={(e) => setCustomQuestion(e.target.value)}
            placeholder="Ask a question about this chapter..."
            disabled={isLoading}
          />
          <HelpButton type="question" />
          {activeTooltip === 'question' && <div className="tooltip">{tooltips.question}</div>}
        </div>
        <button 
          onClick={handleCustomQuestion}
          disabled={isLoading || !customQuestion.trim()}
        >
          Ask Question
        </button>
      </div>

      <div>
        <h4>Response:</h4>
        {isLoading && <p>Loading...</p>}
        {error && <p className="error">{error}</p>}
        {!isLoading && !error && <p>{gptResponse}</p>}
      </div>
      <button onClick={onClose}>Close</button>
    </div>
  );
}

export default ChatGptPanel;
